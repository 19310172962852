<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <div class="row my-1 mx-2">
        <v-btn depressed color="primary" dark @click="OpenDialog('lead', 'none')"
          >+ Add Lead</v-btn
        >
        <template v-if="isAdmin || isSuper || isSupervisor">
          <v-btn class="mx-2" depressed color="info" dark @click="ImportLead()"
            ><v-icon class="mr-1">mdi-cloud-upload</v-icon> Import Lead</v-btn
          ></template
        >

        <v-spacer></v-spacer>
        <template v-if="isSuper">
          <v-btn class="mx-2" depressed color="info" dark @click="googleSheet()"
            ><v-icon class="mr-1">mdi-cog</v-icon> Google Sheet</v-btn
          >
        </template>
      </div>
    </template>
    <div>
      <v-card class="rounded-xl overflow-hidden">
        <v-toolbar class="primary" tag="div" flat dark tile>
          <div class="text-h6">Leads Center</div>

          <template v-if="isAdmin || isSuper || isSupervisor">
            <div class="mx-4 mt-4 pt-1">
              <v-switch
                class="mt-2 mx-4"
                inset
                color="error"
                @change="getLeads()"
                v-model="body.noCoordinator"
                :loading="loading"
                :label="`No ${getCoordinatorN}`"
              ></v-switch>
            </div>
          </template>
          <div class="mx-4 mt-4 pt-1">
            <v-switch
              class="mt-2 mx-4"
              inset
              color="white"
              @change="getLeads()"
              v-model="body.cotizedOnly"
              :loading="loading"
              :label="`Quoted`"
            ></v-switch>
          </div>
          <v-spacer></v-spacer>

          <div class="mx-4 mt-4 pt-2">
            <v-select
              :items="lang"
              v-model="body.preferLanguage"
              item-text="label"
              item-value="value"
              :loading="loading"
              outlined
              @change="getLeads"
              dense
              label="by Language"
            ></v-select>
          </div>

          <template v-if="(isAdmin || isSuper || isSupervisor) && selected.length != 0">
            <asign-to
              :ocupations="ocupations"
              :asignDialog="asignDialog"
              :color="'yellow'"
              @assigned="toGeTLead($event)"
              :leadUuids="selected"
            />
          </template>
          <template v-if="(isAdmin || isSuper || isSupervisor) && selected.length != 0">
            <asign-delete
              :asignDeleteDialog="asignDeleteDialog"
              :ocupations="ocupations"
              :color="'error'"
              @deleted="toGeTLead($event)"
              :leadUuids="selected"
            />
          </template>
          <template v-if="(isAdmin || isSuper || isSupervisor) && selected.length != 0">
            <asign-noms-l
              :openDialog="openDialogNom"
              :nomName="nomName"
              :leadsUuids="selected"
              @updated="toGeTLead($event)"
              :tags="leadTags"
              :status="status"
            />

            <v-menu transition="slide-x-transition" bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" text v-bind="attrs" v-on="on">
                  <v-icon dark> mdi-format-list-bulleted-square </v-icon>
                </v-btn>
              </template>

              <v-list rounded>
                <v-subheader>Options</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="item in itemsMenu"
                    :key="item.key"
                    @click="openOption(item)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="toDeleteLEads" icon
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              <span>Delete Selected Leads</span>
            </v-tooltip>
          </template>
          <v-btn :loading="loading" text @click="getLeads">
            <v-icon>mdi-autorenew</v-icon>
            {{ $t("labelUpdate") }}
          </v-btn>
        </v-toolbar>

        <v-row class="my-1 mx-2">
          <v-col cols="12" sm="3">
            <v-text-field
              v-model.trim="query"
              class="mr-2"
              label="Search or filter"
              hide-details
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="3">
            <ma-date-picker
              :datepickerProps="{
                'no-title': true,
              }"
              v-model="dateFrom"
              label="From"
              past
            />
          </v-col>
          <v-col cols="12" sm="3">
            <ma-date-picker
              :datepickerProps="{
                'no-title': true,
              }"
              v-model="dateTo"
              label="To"
              past
            />
          </v-col>
          <v-col cols="12" sm="3" class="d-flex justify-end">
            <template v-if="dateFrom || dateTo">
              <v-btn fab small dark class="mr-1" color="indigo" @click="refresh('dates')">
                <v-icon dark> mdi-refresh </v-icon>
              </v-btn>
            </template>

            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" fab small dark color="info" @click="gotoConfigure()"
                    ><v-icon>mdi-account-cog</v-icon></v-btn
                  >
                </template>
                <span>Columns Configure</span>
              </v-tooltip>
            </template>
            <v-btn color="primary" text @click="openFilters"
              ><v-icon>mdi-filter-variant</v-icon> Filters</v-btn
            >
          </v-col>

          <v-dialog
            v-model="configureDialog"
            persistent
            :overlay="false"
            max-width="800px"
            transition="dialog-transition"
          >
            <v-card>
              <v-card-title class="headline primary white--text"> Columns </v-card-title>
              <v-card-text class="mt-3">
                <div class="text-center">
                  <h3>Select the columns you want to show:</h3>
                </div>
                <div class="d-flex justify-space-between">
                  <div>
                    <v-checkbox
                      label="Select All"
                      v-model="showAll"
                      color="success"
                      :value="true"
                    ></v-checkbox>
                  </div>
                </div>
                <div class="d-flex flex-wrap mb-4">
                  <v-chip
                    class="ma-2 chipsCols"
                    outlined
                    color="primary"
                    label
                    v-for="(header, i) in headersToChange"
                    :key="i"
                  >
                    <div class="d-flex justify-space-between contentCols">
                      <span>{{ header.text }}</span>

                      <template v-if="header.show">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="success"
                              v-on="on"
                              class="ml-2"
                              right
                              @click="updateHeader(false, i)"
                            >
                              mdi-eye
                            </v-icon>
                          </template>
                          <span>Click to Hide</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="error"
                              v-on="on"
                              class="ml-2"
                              right
                              @click="updateHeader(true, i)"
                            >
                              mdi-eye-off
                            </v-icon>
                          </template>
                          <span>Click to Show</span>
                        </v-tooltip></template
                      >
                    </div>
                  </v-chip>
                </div>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn
                  color="info"
                  :disabled="loadingAcciones"
                  text
                  @click="defaultHeaders"
                  >default</v-btn
                >
                <v-btn
                  :disabled="loadingAcciones"
                  :loading="loadingAcciones"
                  text
                  color="success"
                  @click="setHeaders()"
                  >confirm</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <template>
          <v-data-table
            v-model="selected"
            :loading="loading || loadingAcciones"
            :headers="headers"
            :options.sync="options"
            :items="leadsData"
            :footer-props="footerProps"
            :show-select="isAdmin || isSuper || isSupervisor"
            :server-items-length="totalleads"
            item-key="uuid"
            class="elevation-1 ma-4"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                {{ item.name + " " + item.last_name }}
              </div>
            </template>
            <template v-slot:[`item.coordinator`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                {{ getCoordinator(item) }}
              </div>
            </template>
            <template v-slot:[`item.quote`]="{ item }">
              <template v-if="item.quotedDate">
                <v-icon color="success">mdi-check</v-icon>
              </template>
              <template v-else>
                <v-icon color="error">mdi-close</v-icon>
              </template>
            </template>
            <template v-slot:[`item.quotedDate`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                <template v-if="item.quotedDate">
                  {{ item.quotedDate ? prettyDate(item.quotedDate) : "-" }}
                </template>
                <template v-else>
                  <v-icon color="error">mdi-close</v-icon>
                </template>
              </div>
            </template>
            <template v-slot:[`item.rd`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                {{ getRDate(item) ? prettyDate(getRDate(item)) : "-" }}
              </div>
            </template>
            <template v-slot:[`header.members`]="{ header }">
              <template v-if="totalMembers && totalMembers.count_members">
                {{ `${header.text}(${totalMembers.count_members})` }}
              </template>
              <template v-else>
                {{ `${header.text}` }}{{ loadingSummary ? "(...)" : "" }}
              </template>
            </template>
            <template v-slot:[`header.smsCount`]="{ header }">
              <template v-if="totalMembers && totalMembers.count_sms">
                {{ `${header.text}(${totalMembers.count_sms})` }}
              </template>
              <template v-else>
                {{ `${header.text}` }}{{ loadingSummary ? "(...)" : "" }}
              </template>
            </template>
            <template v-slot:[`header.name`]="{ header }">
              <template v-if="totalMembers && totalMembers.count_leads">
                {{ `${header.text}(${totalMembers.count_leads})` }}
              </template>
              <template v-else>
                {{ `${header.text}` }}{{ loadingSummary ? "(...)" : "" }}
              </template>
            </template>
            <template v-slot:[`header.callCount`]="{ header }">
              <template v-if="totalMembers && totalMembers.count_calls">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{
                      `${header.text}(${totalMembers.count_calls})`
                    }}</span>
                  </template>
                  <div class="d-flex flex-column">
                    <span>Incoming Calls: {{ totalMembers.inbound_calls }}</span>
                    <span>Outgoing Calls: {{ totalMembers.outbound_calls }}</span>
                  </div>
                </v-tooltip>
              </template>
              <template v-else>
                {{ `${header.text}` }}{{ loadingSummary ? "(...)" : "" }}
              </template>
            </template>
            <template v-slot:[`header.status`]="{ header }">
              <div class="d-flex">
                <span class="mt-1">
                  {{ `${header.text}` }}
                </span>
                <template v-if="showStatus.data.length != 0">
                  <v-icon color="success" @click="showStatusSummary">mdi-eye</v-icon>
                </template>
              </div>
            </template>
            <template v-slot:[`header.sourceNom`]="{ header }">
              <div class="d-flex">
                <span class="mt-1">
                  {{ `${header.text}` }}
                </span>
                <template v-if="showSource.data.length != 0">
                  <v-icon color="info" @click="showSourceSummary">mdi-eye</v-icon>
                </template>
              </div>
            </template>
            <template v-slot:[`header.leadTagsNom`]="{ header }">
              {{ `${header.text}` }}
              <!--    <v-icon color="info" @click="showTagSummary">mdi-eye</v-icon> -->
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-menu offset-x>
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                    v-bind:style="{ color: colorShow(item) }"
                    class="font-weight-bold cursor-pointer"
                    >{{ statusShow(item) }}</span
                  >
                </template>
                <v-list>
                  <v-list-item
                    class="cursor-pointer"
                    v-for="(st, index) in status"
                    :key="index"
                    link
                    :disabled="statusShowReal(item) == st.name"
                    @click="changeStatus(item, st)"
                  >
                    <v-list-item-title
                      :class="statusShowReal(item) == st.name ? 'red--text' : ''"
                      >{{ st.name }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.createAt`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                {{ formatDate(item.createAt) }}
              </div>
            </template>
            <template v-slot:[`item.leadTagsNom`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                <template v-if="item.leadTagsNom && item.leadTagsNom.length != 0">
                  <div class="chipstag d-flex">
                    <v-chip
                      class="mr-1"
                      v-for="itm in item.leadTagsNom"
                      :key="itm.id"
                      :color="colorShowT(itm)"
                      dark
                    >
                      {{ itm.tagNom ? itm.tagNom.name : "-" }}
                    </v-chip>
                  </div>
                </template>
                <template v-else>-</template>
              </div>
            </template>

            <template v-slot:[`item.sourceNom`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                <span
                  v-bind:style="{ color: colorShowS(item) }"
                  class="font-weight-bold"
                  >{{ item.sourceNom ? item.sourceNom.name : "-" }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              <template v-if="item.phone">
                <div class="d-flex">
                  <div class="cursor-pointer" @click="makeCallV(item.phone)">
                    {{ item.phone }}
                  </div>
                  <div class="mx-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          @click="copyPhone(item.phone)"
                          v-on="on"
                          small
                          color="info"
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      <span>Copy Phone</span>
                    </v-tooltip>
                  </div>
                </div>
              </template>
              <template v-else> - </template>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex jusitify-end">
                <template>
                  <v-tooltip color="info" top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="primary" :disabled="!item.quotedDate" icon
                        ><v-icon>mdi-currency-usd</v-icon></v-btn
                      >
                    </template>

                    <v-row class="toolt" no-gutters>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Quoted Date</label>
                        <span>{{ formatDate(item.quotedDate) }}</span>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">State</label>
                        <span>{{ item.state }}</span>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Company</label>
                        <span>{{ item.company ? item.company.name : "-" }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="mt-4 toolt" no-gutters>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Members</label>
                        <span>{{ item.members }}</span>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Metal Plan</label>
                        <span
                          class="text-body-1 font-weight-bold"
                          v-bind:style="{
                            color: colorPolicy(item.policyPlan),
                          }"
                          >{{ item.policyPlan }}</span
                        >
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Premium</label>
                        <span>{{ item.premium | currency }}</span>
                      </v-col>
                    </v-row>
                  </v-tooltip>
                </template>

                <v-menu offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list rounded>
                    <v-subheader>Actions</v-subheader>
                    <v-list-item-group v-model="selectedAction" color="primary">
                      <v-list-item
                        v-for="itema in itemsActions"
                        :key="itema.key"
                        @click="openAction(itema, item)"
                      >
                        <v-list-item-icon>
                          <v-icon :color="itema.color" v-text="itema.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="itema.title"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </template>

        <!-- Quote Details -->
        <v-dialog
          v-model="quoteDialog"
          persistent
          :overlay="false"
          max-width="600px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar class="primary" tag="div" flat dark tile>
              <v-toolbar-title> Quote Details </v-toolbar-title></v-toolbar
            >
            <v-card-text class="my-2">
              <v-container>
                <template v-if="quoteDetails">
                  <v-row no-gutters>
                    <v-col cols="12" sm="4" class="d-flex flex-column">
                      <label class="font-weight-bold" for="">Quoted Date</label>
                      <span>{{ formatDate(quoteDetails.quotedDate) }}</span>
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex flex-column">
                      <label class="font-weight-bold" for="">State</label>
                      <span>{{ quoteDetails.state }}</span>
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex flex-column">
                      <label class="font-weight-bold" for="">Company</label>
                      <span>{{
                        quoteDetails.company ? quoteDetails.company.name : "-"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4" no-gutters>
                    <v-col cols="12" sm="4" class="d-flex flex-column">
                      <label class="font-weight-bold" for="">Members</label>
                      <span>{{ quoteDetails.members }}</span>
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex flex-column">
                      <label class="font-weight-bold" for="">Metal Plan</label>
                      <span
                        class="text-body-1 font-weight-bold"
                        v-bind:style="{
                          color: colorPolicy(quoteDetails.policyPlan),
                        }"
                        >{{ quoteDetails.policyPlan }}</span
                      >
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex flex-column">
                      <label class="font-weight-bold" for="">Premium</label>
                      <span>{{ quoteDetails.premium | currency }}</span>
                    </v-col>
                  </v-row>
                </template>
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="info" text @click="closeDialog">close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--  Delete Lead -->
        <v-dialog
          v-model="deleteL.deleteDialog"
          persistent
          :overlay="false"
          max-width="300px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar dark color="error">
              Delete Lead: {{ deleteL.deleteItem.name }}
            </v-toolbar>
            <v-card-text class="mt-3">
              <div class="pa-2 text-center body-1">Are you sure ?</div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelDelete">Cancel</v-btn>
              <v-btn text :loading="loadingAcciones" @click="confirmDelte" color="error"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="toDeleteLeads.dialog"
          persistent
          :overlay="false"
          max-width="300px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar dark color="error"> Delete Leads </v-toolbar>
            <v-card-text class="mt-3">
              <div class="pa-2 text-center body-1">Are you sure ?</div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelDeleteLEads">Cancel</v-btn>
              <v-btn
                text
                :loading="toDeleteLeads.loading"
                @click="confirmDeleteLEads"
                color="error"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="showStatus.dialog"
          :overlay="false"
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <template>
              <v-data-table
                :headers="showStatus.headers"
                :items="showStatus.data"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    class="text-body-1 cursor-pointer"
                    v-bind:style="{ color: colorShow(item) }"
                  >
                    {{ statusShow(item) }}
                  </div>
                </template>
              </v-data-table>
            </template>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="showSource.dialog"
          :overlay="false"
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <template>
              <v-data-table
                :headers="showSource.headers"
                :items="showSource.data"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:[`item.source`]="{ item }">
                  <div
                    class="text-body-1 cursor-pointer font-weight-bold"
                    v-bind:style="{ color: colorSourceEye(item) }"
                  >
                    {{ item.source }}
                  </div>
                </template>
              </v-data-table>
            </template>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="showTags.dialog"
          :overlay="false"
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <template>
              <v-data-table
                :headers="showTags.headers"
                :items="showTags.data"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:[`item.source`]="{ item }">
                  <div
                    class="text-body-1 cursor-pointer font-weight-bold"
                    v-bind:style="{ color: colorSourceEye(item) }"
                  >
                    {{ item.source }}
                  </div>
                </template>
              </v-data-table>
            </template>
          </v-card>
        </v-dialog>
      </v-card>
      <template v-if="filterExpandLead">
        <v-navigation-drawer v-model="filterExpandLead" app v-cloak>
          <div class="d-flex pa-2">
            <span class="py-1">Filters</span><v-spacer></v-spacer
            ><v-btn text @click="closeFilters"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              hide-details
              clearable
              outlined
              @change="getLeads()"
              dense
              :items="references"
              item-text="name"
              item-value="id"
              v-model="body.sourceId"
              label="Filter by Source"
            >
              <!-- <template v-slot:prepend-item>
                <v-text-field
                  class="px-2"
                  prepend-inner-icon="mdi-magnify-plus-outline"
                  placeholder="Custom source"
                  clearable
                  @click:clearable="getLeads()"
                  @change="getLeads()"
                  v-model="body.sourceId"
                ></v-text-field>
              </template> -->
              <!--   <template v-slot:selection>
                <span>{{ body.howKnow }}</span>
              </template> -->
            </v-autocomplete>
          </div>
          <v-divider></v-divider>
          <template v-if="isAdmin || isSuper || isSupervisor">
            <div class="col-sm-12">
              <v-autocomplete
                :label="`Filter by Employees`"
                :items="workers"
                v-model="body.coordinatorUuid"
                item-text="fullname"
                item-value="uuid"
                style="max-width: 500px"
                prepend-inner-icon="mdi-account-search"
                clearable
                @change="getLeads()"
                hide-details
                outlined
                dense
              ></v-autocomplete>
            </div>
            <v-divider></v-divider>
          </template>

          <div class="col-sm-12">
            <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              hide-details
              clearable
              outlined
              dense
              :items="status"
              multiple
              item-text="name"
              item-value="id"
              v-model="statusNomIds"
              label="Filter by Status"
            >
              <template v-slot:prepend-inner>
                <template v-if="!checkAllStatus">
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="success"
                          @click="checkAllStatus = true"
                          v-text="'mdi-check-all'"
                        ></v-icon>
                      </template>
                      <span>Select All Status</span>
                    </v-tooltip>
                  </v-slide-x-reverse-transition>
                </template>
              </template>
            </v-autocomplete>
          </div>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-select
              v-model="tagNomIds"
              label="by Tags"
              :items="leadTags"
              multiple
              prepend-inner-icon="mdi-magnify"
              item-text="name"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
            >
              <template v-slot:prepend-inner>
                <template v-if="!checkAllTags">
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="success"
                          @click="checkAllTags = true"
                          v-text="'mdi-check-all'"
                        ></v-icon>
                      </template>
                      <span>Select All Tags</span>
                    </v-tooltip>
                  </v-slide-x-reverse-transition>
                </template>
              </template>
            </v-select>
          </div>
          <div class="col-sm-12 d-flex justify-end">
            <v-btn color="error" @click="clearFilters">Clear filters</v-btn>
          </div>
        </v-navigation-drawer></template
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
/* import { Container, Draggable } from "vue-smooth-dnd"; */
/* import LeadDashboardGroup from "./LeadDasboardGroup.vue"; */

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import SendEmail from "@/components/emails/SendEmail.vue";
import TableLeads from "@/views/Configurations/components/TableLeads.vue";

import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import TableCalls from "@/views/Configurations/components/TableCalls.vue";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";

import AsignTo from "@/views/Configurations/components/AsignTo.vue";
import AsignDelete from "@/views/Configurations/components/AsignDelete.vue";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { Lead, LeadStatus } from "@/models/Lead";

import { desireSurgery } from "@/utils/consts";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo, notifySuccess } from "@/components/Notification";

import moment from "moment";
import AllImages from "@/components/Images/AllImages.vue";
import { funtionsPhone } from "@/utils/mixins";
import { cleanPhoneNumber, isPhoneNumber } from "@/utils/formatPhone";
import AsignNomsL from "./components/AsignNomsL.vue";

export default Vue.extend({
  mixins: [funtionsPhone],
  components: {
    /*  LeadDashboardGroup, */
    /* Draggable, */
    /*  Container, */
    SendEmail,
    MaDatePicker,
    DrawerSms,
    TableLeads,

    TableCalls,
    BtnMakeCall,
    AsignTo,
    AllImages,
    AsignDelete,
    AsignNomsL,
  },
  data: () => ({
    ocupations: [],
    quoteDialog: false,
    quoteDetails: null,
    loadingAcciones: false,
    loadingSummary: false,
    totalMembers: null,
    filterExpandLead: false,
    showStatus: {
      dialog: false,
      data: [],
      headers: [
        { text: "Status", value: "status", sortable: false },
        { text: "Leads", value: "count_leads", sortable: false },
      ],
    },
    showSource: {
      dialog: false,
      data: [],
      headers: [
        { text: "Source", value: "source", sortable: false },
        { text: "Leads", value: "count_leads", sortable: false },
      ],
    },
    showTags: {
      dialog: false,
      data: [],
      headers: [
        { text: "Tag", value: "tag", sortable: false },
        { text: "Leads", value: "count_leads", sortable: false },
      ],
    },
    preferLanguage: null,
    lang: [
      { label: "All", value: null },
      { label: "English", value: "ENGLISH" },
      { label: "Spanish", value: "SPANISH" },
    ],
    deleteL: {
      deleteItem: {},
      deleteDialog: false,
    },
    toDeleteLeads: {
      dialog: false,
      items: [],
      loading: false,
    },
    desireSurgery,
    showcalendar: false,
    changed: false,
    formValid: false,
    selectedItem: null,
    selectedAction: null,
    asignDialog: false,
    openDialogNom: false,
    nomName: null,
    asignDeleteDialog: false,
    evaluation: false,
    tabSelect: null,
    title: "",
    types: [],
    footerProps: { "items-per-page-options": [15, 50, 100, 500, 1000] },
    details: {},

    options: {},
    dialogNote: false,
    dialogLead: false,
    selected: [],
    detailsnote: "",
    loadingProcedures: false,

    dateTo: "",
    dateFrom: "",
    uuidLead: "",
    query: null,
    tagNomIds: [],
    statusNomIds: null,
    checkAllTags: false,
    checkAllStatus: false,
    cleaning: false,
    leadsData: [],
    body: {
      query: null,
      dates: null,
      statusNomIds: null,
      noCoordinator: false,
      cotizedOnly: false,
      preferLanguage: null,
      tagNomIds: [],
      coordinatorUuid: null,
      sourceId: null,
      interval: {
        offset: 10,
        limit: 0,
      },
    },
    upperDropPlaceholderOptions: {
      className: "cards-drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    expanded: [],
    status: [],
    headersToChange: [],
    configureDialog: false,
    showAll: false,
  }),
  computed: {
    ...mapGetters([
      "getCoordinatorN",
      "getpatientN",
      "isSupervisor",
      "isAdmin",
      "isSuper",
      "getleadHeaders",
    ]),
    ...mapState(["dataCRM", "configId"]),
    ...mapGetters("crmConfigurationsModule", ["headersLeadShow", "headersLead"]),
    ...mapState("crmConfigurationsModule", ["leads", "loading", "totalleads"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references", "tags"]),
    ...mapState("crmEmployeeModule", ["doctors", "workers"]),

    ...mapState("crmCallCenterModule", ["callsLead"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    /*  dateRangeText() {
      let temp = (this).body.dates;
      if ((this).body.dates.length > 1) {
        const val1 = (this).body.dates[0].split("-").splice(2, 1);
        const val2 = (this).body.dates[1].split("-").splice(2, 1);
        if (Number(val1) > Number(val2)) {
          temp = [];
          temp.push((this).body.dates[1]);
          temp.push((this).body.dates[0]);
        }
      }
      return temp.join(" ~ ");
    }, */

    leadTags() {
      let lt = this.tags.sort((a, b) => {
        return b.isLead - a.isLead;
      });
      lt = lt.filter((r) => r.status == "ACTIVE");
      return lt;
    },

    itemsMenu() {
      return [
        { title: "Create Relationship", icon: "mdi-account-network", key: "relations" },
        { title: "Delete Relationship", icon: "mdi-close-network", key: "drelations" },
        { title: "Manage Status", icon: "mdi-shuffle-variant", key: "Status" },
        { title: "Manage Tags", icon: "mdi-marker-check", key: "Tags" },
      ];
    },
    itemsActions() {
      return this.isAdmin || this.isSuper
        ? [
            { title: "Lead Details", icon: "mdi-eye", key: "details", color: "success" },
            { title: "Edit Lead", icon: "mdi-pencil", key: "edit", color: "info" },
            { title: "Delete Lead", icon: "mdi-delete", key: "delete", color: "error" },
          ]
        : [
            { title: "Lead Details", icon: "mdi-eye", key: "details", color: "success" },
            { title: "Edit Lead", icon: "mdi-pencil", key: "edit", color: "info" },
          ];
    },

    desireSurgerySelect() {
      if (this.expanded.length == 0 || this.expanded[0].medicalHistory == null) {
        return null;
      }
      if (this.expanded[0].medicalHistory.desiredSurgeryDate) {
        const t = this.expanded[0].medicalHistory.desiredSurgeryDate;
        const desire = this.desireSurgery.filter((d) => d.value == t);
        return desire[0];
      }
      return null;
    },

    headers() {
      return this.headersLeadShow;
    },
    bmi() {
      let inches = 0;
      let feets = 0;
      if (this.expanded.length == 0) {
        return 0;
      }
      if (this.expanded[0].weight == 0 && this.expanded[0].height == 0) return 0;
      const he = this.expanded[0].height.toString();
      if (he.includes(".")) {
        inches = Number(he.split(".")[1]);
        feets = he.split(".")[0] ? Number(he.split(".")[0]) : 0;
      } else if (he.includes(",")) {
        inches = Number(he.split(",")[1]);
        feets = he.split(".")[0] ? Number(he.split(",")[0]) : 0;
      } else {
        feets = he;
      }

      const inchtometer = inches * 0.0254;
      const feettometer = feets * 0.3048;
      const bmi =
        (Number(this.expanded[0].weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);

      return Number(bmi).toFixed(2);
    },

    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red darken-2";
      }
    },
  },
  async mounted() {
    this.mutSetHeaders(this.dataCRM.columnsLeads);
    await this.loadLeadStatus();
    this.listOcupations();
    this.actListReferences();
    this.actListTags();
    this.actGetPatientDoctors();
    if (this.isAdmin || this.isSuper) {
      await this.actGetEmployees();
    }

    localStorage.removeItem("from");
  },
  watch: {
    expanded(val) {
      if (val != []) {
        this.expandedLead();
      }
    },
    showAll(val) {
      if (val == true) {
        this.setAll(true);
      } else {
        this.setAll(false);
      }
    },
    options(val) {
      if (val != {}) {
        this.getLeads();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.getLeads();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.getLeads();
      }
    },
    query(val) {
      if (val == null) {
        this.body.query = val;
        this.getLeads();
      } else if (val.length >= 3 || val.length == 0) {
        if (isPhoneNumber(val)) {
          this.query = cleanPhoneNumber(val);
          if (this.query[0] == "1") {
            this.query = this.query.slice(1);
          }
          this.body.query = this.query;
        } else {
          this.body.query = val;
        }
        this.getLeads();
      }
    },
    tagNomIds: {
      handler(val) {
        this.body.tagNomIds = val;
        if (!this.cleaning) {
          this.getLeads();
        }

        if (val && val.length != this.leadTags.length) {
          this.checkAllTags = false;
        } else if (
          val &&
          val.length == this.leadTags.length &&
          this.checkAllTags == false
        ) {
          this.checkAllTags = true;
        }
      },
    },
    statusNomIds: {
      handler(val) {
        this.body.statusNomIds = val;
        if (!this.cleaning) {
          this.getLeads();
        }

        if (val && val.length != this.status.length) {
          this.checkAllStatus = false;
        } else if (
          val &&
          val.length == this.status.length &&
          this.checkAllStatus == false
        ) {
          this.checkAllStatus = true;
        }
      },
    },
    checkAllTags(val) {
      if (val) {
        if (this.tagNomIds == null || this.leadTags.length != this.tagNomIds.length) {
          this.tagNomIds = this.leadTags.map((t) => {
            return t.id;
          });
        }
      }
    },
    checkAllStatus(val) {
      if (val) {
        if (this.statusNomIds == null || this.status.length != this.statusNomIds.length) {
          this.statusNomIds = this.status.map((t) => {
            return t.id;
          });
        }
      }
    },
  },
  destroyed() {
    localStorage.removeItem("leadcenter");
    this.mutSetLeads([]);
    this.mutSetTotalLeads(0);
    this.closeFilters();
  },
  methods: {
    ...mapActions("crmConfigurationsModule", [
      "actGetLeads",
      "actConverToPatient",
      "actAddNote",
      "actAddLead",
    ]),

    ...mapActions("crmEmployeeModule", ["actGetEmployees", "actGetPatientDoctors"]),
    ...mapActions("crmCallCenterModule", ["actFilterCalls"]),
    ...mapMutations("crmConfigurationsModule", [
      "mutleadSelect",
      "mutSetLeads",
      "mutSetTotalLeads",
      "mutSetHeaders",
    ]),
    ...mapActions(["actUpdateSettings"]),
    ...mapActions("crmSettingsModule", ["actListReferences", "actListTags"]),
    ...mapMutations([
      "mutReqRange",
      "mutFilterDrawerExpandLead",
      "mutDrawerExpand",
      "mutDataCRMColumnsLeads",
    ]),
    openFilters() {
      this.filterExpandLead = !this.filterExpandLead;
      this.mutFilterDrawerExpandLead(this.filterExpandLead);
      this.mutDrawerExpand(this.filterExpandLead);
    },
    closeFilters() {
      this.filterExpandLead = false;
      this.mutFilterDrawerExpandLead(this.filterExpandLead);
      this.mutDrawerExpand(this.filterExpandLead);
    },

    gotoLead(item) {
      this.$router.push(`/leads_details/${item.uuid}`);
    },

    setAll(val) {
      this.headersToChange.forEach((element) => {
        element.show = val;
      });
    },

    setHeaders() {
      this.mutSetHeaders(this.headersToChange);
      this.mutDataCRMColumnsLeads(this.headersToChange);
      this.saveSettings();
    },
    gotoConfigure() {
      this.headersToChange = this.headersLead.map((ele) => {
        return ele;
      });
      this.configureDialog = true;
    },

    updateHeader(value, index) {
      this.headersToChange[index].show = value;
    },
    defaultHeaders() {
      this.headersToChange = [
        {
          text: "Creation Date",
          align: "start",
          sortable: false,
          value: "createAt",
          show: true,
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          show: true,
        },
        // { text: "Email", value: "email", sortable: false, show: false },
        { text: "Phone", value: "phone", sortable: false, show: true },
        { text: "Source", value: "sourceNom", sortable: false, show: true },
        { text: "Language", value: "preferLanguage", sortable: false, show: true },

        { text: "Agent", value: "coordinator", sortable: false, show: true },
        {
          text: "R-Date",
          align: "start",
          width: 110,
          value: "rd",
          sortable: false,
          show: true,
        },
        {
          text: "Members",
          value: "members",
          align: "center",
          sortable: false,
          show: false,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
          show: true,
        },
        {
          text: "Quote Date",
          value: "quotedDate",
          align: "center",
          sortable: false,
          show: false,
        },
        {
          text: "Sms",
          value: "smsCount",
          sortable: false,
          align: "center",
          show: true,
        },
        {
          text: "Calls",
          value: "callCount",
          sortable: false,
          align: "center",
          show: true,
        },
        {
          text: "Options",
          value: "actions",
          align: "center",
          sortable: false,
          show: true,
        },
        { text: "Tags", value: "leadTagsNom", sortable: false, show: false },
      ];
      this.mutSetHeaders(this.headersToChange);
      this.mutDataCRMColumnsLeads(this.headersToChange);
      this.saveSettings();
    },

    saveSettings() {
      this.loadingAcciones = true;
      const valor = JSON.stringify(this.dataCRM);
      const settings = {
        name: "general_config",
        description: "All configurations ",
        value: valor,
        isPublic: true,
        uuid: this.configId,
        active: true,
      };
      this.actUpdateSettings(settings)
        .then(() => {
          this.loadingAcciones = false;
          this.configureDialog = false;
          notifySuccess("Columns has been update");
        })
        .catch((error) => {
          let mess = error.response.data.message;
          this.loadingAcciones = false;
          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    showQuoted(item) {
      this.quoteDialog = true;
      this.quoteDetails = {
        quotedDate: item.quotedDate,
        company: item.company,
        members: item.members,
        premium: item.premium,
        state: item.state,
        policyPlan: item.policyPlan,
      };
    },
    closeDialog() {
      this.quoteDialog = false;
      this.quoteDetails = null;
    },

    changeStatus(item, status) {
      this.loadingAcciones = true;
      let body = {
        leadUuid: item.uuid,
        statusNom: status.id,
      };
      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifyInfo("Lead Status has been changed");
          this.loadingAcciones = false;
          this.getLeads();
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    loadLeadStatus() {
      getAPI(`/lead-status-nom/list`)
        .then((res) => {
          this.status = res.data.filter((f) => f.status == "ACTIVE");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async listOcupations() {
      this.ocupations = (await getAPI(`/ocupation-nom/list`)).data;
    },
    statusShow(item) {
      const est = item.statusNom
        ? item.statusNom.name
        : item.status
        ? item.status
        : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      switch (est) {
        case LeadStatus.NotInterested:
          return "Not Interested";
        case LeadStatus.WrongNumber:
          return "Wrong Number";
        default:
          return est;
      }
    },
    statusShowReal(item) {
      const est = item.statusNom ? item.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      return est;
    },
    colorShow(item) {
      return item.statusNom ? item.statusNom.color : "#000";
    },
    colorSourceEye(data) {
      if (this.references.length != 0) {
        const x = this.references.find((r) => r.name == data.source);
        return x ? x.color : "#000";
      }
      return "#000";
    },
    colorShowS(item) {
      return item.sourceNom ? item.sourceNom.color : "#000";
    },
    colorShowT(item) {
      return item.tagNom ? item.tagNom.color : "#000";
    },

    convertToPatient(lead) {
      this.mutleadSelect(lead);
      this.$router.push("/clients/create");
    },
    goEdit(item) {
      localStorage.setItem("from", "center");
      this.$router.push("/leads_center/edit/" + item.uuid);
    },
    async expandedLead() {
      if (this.expanded.length != 0) {
        this.uuidLead = this.expanded[0].uuid;
        this.details = this.expanded[0];
        const phone = this.expanded[0].phone;
        await this.actFilterCalls({
          whateverNumber: phone,
          limit: 500,
          offset: 0,
        });
      } else {
        this.uuidLead = "";
        this.details = {};
      }
    },

    toIsoString(values) {
      let temp = [];
      if (values.length == 1 || values[0] == values[1]) {
        const date1 = new Date(values[0]);
        const date2 = new Date(values[0]);
        date2.setUTCHours(23, 59);
        temp.push(date1.toISOString());
        temp.push(date2.toISOString());
      } else {
        values.forEach((val) => {
          const date = new Date(val);
          temp.push(date.toISOString());
        });
      }

      return temp;
    },

    toDelete(item) {
      this.selectedAction = null;
      this.deleteL.deleteDialog = true;
      this.deleteL.deleteItem = item;
    },
    cancelDelete() {
      this.deleteL = { deleteItem: {}, deleteDialog: false };
    },
    confirmDelte() {
      this.loadingAcciones = true;
      getAPI
        .delete("/social/delete/" + this.deleteL.deleteItem.uuid)
        .then(() => {
          notifyInfo("Lead has been deleted");
          this.loadingAcciones = false;
          this.cancelDelete();
          this.getLeads();
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    async getLeads() {
      this.selectedAction = null;
      this.selected = [];
      let reqbody = {};
      if (localStorage.getItem("leadcenter") && localStorage.getItem("filtersleads")) {
        const localdata = JSON.parse(localStorage.getItem("filtersleads"));
        this.body = localdata.body;
        this.dateTo = localdata.dateto;
        this.dateFrom = localdata.datefrom;
        this.options.page = localdata.page;
        this.options.itemsPerPage = localdata.itemsPerPage;
      }

      reqbody = {
        query: this.body.query,
        statusNomIds: this.body.statusNomIds,
        noCoordinator: this.body.noCoordinator,
        tagNomIds: this.body.tagNomIds,
        cotizedOnly: this.body.cotizedOnly,
        preferLanguage: this.body.preferLanguage,
        coordinatorUuid: this.body.coordinatorUuid,
        interval: this.body.interval,
        sourceId: this.body.sourceId,
      };

      if (this.body.dates != null) {
        reqbody = { ...reqbody, dateInterval: this.body.dates };
      }
      if (
        this.dateTo != "" &&
        this.dateTo != null &&
        this.dateTo != undefined &&
        this.dateFrom != "" &&
        this.dateFrom != null &&
        this.dateFrom != undefined
      ) {
        reqbody = {
          ...reqbody,
          dateInterval: {
            date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
            date2: moment(this.dateTo).endOf("day").utc().toISOString(),
          },
        };
      }
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != undefined ? itemsPerPage : 15;
      const p = page != undefined ? page : 1;
      const reqrange = {
        limit: itmper,
        offset: (p - 1) * itmper,
      };
      reqbody.interval = reqrange;

      this.body = {
        query: reqbody.query,
        statusNomIds: reqbody.statusNomIds,
        noCoordinator: reqbody.noCoordinator,
        cotizedOnly: reqbody.cotizedOnly,
        interval: reqbody.interval,
        tagNomIds: reqbody.tagNomIds,
        preferLanguage: reqbody.preferLanguage,
        dates: reqbody.dateInterval ? reqbody.dateInterval : null,
        coordinatorUuid: reqbody.coordinatorUuid,
        sourceId: reqbody.sourceId,
      };
      if (reqbody.tagNomIds && reqbody.tagNomIds.length == 0) {
        delete reqbody.tagNomIds;
      }
      if (reqbody.statusNomIds && reqbody.statusNomIds.length == 0) {
        delete reqbody.statusNomIds;
      }
      reqbody = this.cleanNull(reqbody);

      localStorage.setItem(
        "filtersleads",
        JSON.stringify({
          body: this.body,
          dateto: this.dateTo,
          datefrom: this.dateFrom,
          page,
          itemsPerPage,
        })
      );
      localStorage.removeItem("leadcenter");
      await this.actGetLeads(reqbody);
      this.leadsData = this.leads.map((l) => {
        return { ...l, leadTagsNom: this.getOrderTags(l.leadTagsNom) };
      });

      this.getLeadSummary(reqbody);
    },
    getOrderTags(tags) {
      const temp = tags.sort((a, b) => a.tagNom.id - b.tagNom.id);
      return temp;
    },
    getLeadSummary(filters) {
      delete filters.interval;
      this.loadingSummary = true;
      getAPI
        .post("/social/summaryFilterLeads", filters)
        .then((res) => {
          this.loadingSummary = false;
          const response = res.data;
          const t = response.find((r) => r.status == "TOTAL");
          const data = response.filter((r) => r.status != "TOTAL" && r.source == null);
          const dataS = response.filter((r) => r.status != "TOTAL" && r.source != null);
          this.totalMembers = t;
          this.showStatus.data = data;
          this.showSource.data = dataS;
        })
        .catch((error) => {
          this.loadingSummary = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    showStatusSummary() {
      this.showStatus.dialog = true;
    },
    showSourceSummary() {
      this.showSource.dialog = true;
    },
    showTagSummary() {
      this.showTags.dialog = true;
    },
    toDeleteLEads() {
      this.toDeleteLeads.dialog = true;
      this.toDeleteLeads.items = this.selected.map((s) => {
        return s.uuid;
      });
    },
    cancelDeleteLEads() {
      this.selectedAction = null;
      this.toDeleteLeads = { dialog: false, loading: false, items: [] };
    },
    confirmDeleteLEads() {
      this.toDeleteLeads.loading = true;

      const body = { leadUuids: this.toDeleteLeads.items };
      getAPI
        .delete("/social/deleteMasiveLead", { data: body })
        .then((res) => {
          this.cancelDeleteLEads();
          this.getLeads();
        })
        .catch((error) => {
          this.toDeleteLeads.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },
    getHeight(height) {
      if (height == undefined || height == null || height == "") {
        return 0;
      }
      const ht = height.toString();
      if (ht.includes(".")) {
        return ht.split(".")[0] + " ft, " + ht.split(".")[1] + " in";
      } else if (ht.includes(",")) {
        return ht.split(",")[0] + " ft, " + ht.split(",")[1] + " in";
      } else {
        return ht + " ft";
      }
    },

    getDoctorName(item) {
      if (item.medicalHistory.doctorChoice != null) {
        return item.medicalHistory.doctorChoice.fullname;
      }
      return "None";
    },

    /*  formatTime(date) {
      if (!date) return null;
      const [fecha, hora] = date.split("T");
      let [hours, minutes, seconds] = hora.split(":");
      seconds = seconds.substr(-20, 2);
      return `${hours}:${minutes}:${seconds}`;
    }, */

    showDetails(patient) {
      this.$router.push(`/clients/details/${patient.uuid}`);
    },

    OpenDialog(type, uuid) {
      switch (type) {
        case "note":
          this.dialogNote = true;
          break;
        default:
          this.$router.push(`/leads_center/new_lead`);

          break;
      }
      this.uuidLead = uuid ? uuid : "none";
    },
    ImportLead() {
      this.$router.push(`/leads_center/import_lead`);
    },
    googleSheet() {
      this.$router.push(`/leads_center/google_sheet_list`);
    },

    updateFiles() {
      setTimeout(() => {
        this.$refs.images.getImages();
      }, 500);
    },

    async addNote() {
      await this.actAddNote({
        details: this.detailsnote,
        leadUuid: this.uuidLead,
        type: this.evaluation ? "EVALUATION" : "ANY",
      });
      this.cancelDialog();
      this.getLeads();
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    getCoordinator(item) {
      if (
        item.relations == null ||
        item.relations == undefined ||
        item.relations.length == 0
      ) {
        return "-";
      } else {
        const x = item.relations[0];
        if (x) {
          return x.employee.fullname;
        }
        return "-";
      }
    },
    getRDate(item) {
      if (
        item.relations == null ||
        item.relations == undefined ||
        item.relations.length == 0
      ) {
        return null;
      } else {
        const x = item.relations[0];
        if (x) {
          return x.createdAt;
        }
        return null;
      }
    },

    prettyDate(date) {
      if (date === null || date === "") return "-";

      return moment(date).format("MM/DD/YYYY");
    },

    cancelDialog() {
      this.dialogNote = false;
      this.loadingAcciones = false;
      this.dialogLead = false;
      this.evaluation = false;
      this.descriptioncall = "";
      this.detailsnote = "";
    },

    refresh(type) {
      switch (type) {
        case "dates":
          this.dateTo = "";
          this.dateFrom = "";
          this.body.dates = null;
          break;
        default:
          this.body.query = null;
          break;
      }
      this.getLeads();
    },

    colorPolicy(value) {
      switch (value) {
        case "GOLD":
          return "#EDC43D";
        case "SILVER":
          return "#C0C0C0";
        case "BRONZE":
          return "#CD7F32";

        default:
          return "#000";
      }
    },

    async clearFilters() {
      this.cleaning = true;
      this.body.sourceId = null;
      this.body.statusNomIds = null;
      this.body.coordinatorUuid = null;
      this.body.tagNomIds = [];
      this.statusNomIds = null;
      this.tagNomIds = [];
      this.checkAllStatus = false;
      this.checkAllTags = false;

      await this.getLeads();
      this.cleaning = false;
    },
    toGeTLead(value) {
      this.asignDialog = false;
      this.asignDeleteDialog = false;
      this.openDialogNom = false;
      this.nomName = null;
      this.selectedItem = null;
      if (value) {
        this.getLeads();
      }
    },

    openAction(itema, item) {
      switch (itema.key) {
        case "details":
          this.selectedAction = null;
          this.$router.push(`/leads_details/${item.uuid}`);
          break;

        case "edit":
          this.selectedAction = null;
          this.goEdit(item);
          break;

        case "delete":
          this.selectedAction = null;
          this.toDelete(item);
          break;
      }
    },

    openOption(item) {
      this.nomName = item.key;
      switch (item.key) {
        case "relations":
          this.asignDialog = true;
          break;
        case "drelations":
          this.asignDeleteDialog = true;
          break;
        case "Status":
          this.openDialogNom = true;
          break;
        case "Tags":
          this.openDialogNom = true;
          break;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.cardexpand {
  width: 100vh !important;
}

.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}

.texts1 {
  margin-top: -40px !important;
  margin-bottom: 10px !important;
}

.texts2 {
  margin-top: -40px !important;
  margin-bottom: 8px !important;
}

.actions {
  margin-top: -50px !important;
}

.texts3 {
  margin-top: -40px !important;
  margin-bottom: 5px !important;
}

.swipri {
  margin-top: -1px !important;
}

.borderdivider {
  border: 1px solid;
}

.toolt {
  width: 320px !important;
}

.test {
  border: 1px solid red;
}

.leaddetails {
  text-decoration: none;
  margin: 2px 0px;
}

.contentCols {
  width: 160px !important;
}
</style>
