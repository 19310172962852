<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    
    <template>
      <v-card>
        <v-toolbar color="error" dark>Delete Relationship</v-toolbar>
        <v-card-text class="d-flex justify-center mt-2">
          <v-autocomplete
            class="mx-1 my-3"
            :label="`Select ${getCoordinatorN}s`"
            :items="workers"
            v-model="doctorUuid"
            item-text="fullname"
            item-value="uuid"
            style="max-width: 350px"
            prepend-inner-icon="mdi-account-search"
            clearable
            multiple
            hide-details
            outlined
            dense
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.fullname"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.rol"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancel(false)">Close</v-btn>
          <v-btn
            :disabled="doctorUuid == '' || doctorUuid == null || loadingAction"
            :loading="loadingAction"
            color="error"
            text
            @click="confirm()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default Vue.extend({
  props: {
    leadUuid: String,
    leadUuids: [],
    color: String,
    asignDeleteDialog:Boolean
  },
  name: "crm-asign-delete",
  data() {
    return {
      dialog: false,
      doctorUuid: [],
      loading: false,
      loadingAction: false,
    };
  },
  watch:{
    asignDeleteDialog(val){
      this.dialog=val
    }
  },

  computed: {
    ...mapGetters(["getCoordinatorN"]),
    ...mapState("crmEmployeeModule", ["workers"]),
  },
  mounted() {
    if (this.workers.length == 0) {
      this.actGetEmployees();
    }
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", [
      "actAsignToLead",
      "actMultiAsignToLead",
    ]),

    cancel(value) {
      this.dialog = false;
      this.doctorUuid = [];
      this.loadingAction = false;
      this.$emit("deleted",value);
    },

    async confirm() {
      const leadTransfer = this.leadUuids.map((lead) => lead.uuid);
      let body = {
        leadUuids: leadTransfer,

        employeeUuids: this.doctorUuid,
      };
      this.loadingAction = true;
      getAPI
        .post("/patient/deleteMultipleRelations", body)
        .then(() => {
          notifyInfo(this.$t("Realtionship has been deleted"));
          this.cancel(true);
          
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
</script>
<style lang="scss" scope></style>
